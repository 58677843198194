<template>
  <v-container fluid>
      <v-row>
          <v-col>
                <trev-report-add></trev-report-add>
          </v-col>
      </v-row>      
  </v-container>
</template>

<script>
import trevReportAdd from '../../../../components/reports/sql/trev-report-add.vue'
export default {
  components: { trevReportAdd },

}
</script>

<style>

</style>